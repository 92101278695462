.Conversations__Log {
    .react-select {
        width: 300px !important;
        margin: auto;
        margin-bottom: 8px;
    }
    .Conversations__Log--Dashboard {
        display: flex;
        width: 90%;
        margin: auto;
        border: 1px solid lightgray;
        max-height: 95vh;
        .Left__Block {
            border-right: 1px solid lightgray;
            width: 30%;
            .Search__Params {
                border-bottom: 1px solid lightgrey;
                padding: 8px;
                height: 150px;
                .ant-input-search {
                    width: 100%;
                }
                & > div, span {
                    margin-top: 4px;
                }
            }
            .Available__Conversations {
                overflow-y: scroll;
                max-height: calc(100% - 150px);
                .Single__Chat--Button {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;
                    padding: 4px;
                    p {
                        margin: 0;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        .Right__Block {
            width: 100%;
            .Top__Block {
                padding: 8px;
                display: flex;
                flex-direction: column;
                align-items: start;
                height: 280px;
                background-color: antiquewhite;
                & > span {
                    width: 100%;
                    align-items: start;
                    flex-direction: column;
                    display: flex;
                }
                .Creatable__Select {
                    width: 100%;
                }
            }
        }
    }
    .Container {
        border: 1px solid #F2C94C;
        border-radius: 6px;
        max-height: 80vh;
        overflow-y: scroll;
        height: calc(100% - 280px);
        //overflow-x: auto;
        h6 {
            padding: 8px;
        }
        .Single__Text {
            display: flex;
            text-align: start;
            margin-bottom: 8px;
            padding: 8px;
            span {
                width: 32px;
                height: 32px;
                display: contents;
            }
            div {
                margin-left: 16px;
                border-radius: 6px;
                padding: 8px;
                color: #000;
                background-color: #F2F4F7;
            }
            .system {
                background-color: #fffaab;
            }
            .user {
                color: #000;
                background-color: #F2F4F7;
            }
            .ai {
                color: #fff;
                background-color: #57d497;
            }
        }
    }
}