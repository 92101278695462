/* @font-face {
  font-family: 'MyFont';
  src: url("./fonts/");
  src: url("./fonts/?#iefix") format("embedded-opentype");
  src: url("./fonts/11469c307f0de6481e3a04cc5d54ae93.woff2") format("woff2"); 
  src: url("./fonts/11469c307f0de6481e3a04cc5d54ae93.woff") format("woff");
  src: url("./fonts/11469c307f0de6481e3a04cc5d54ae93.ttf") format("truetype"); 
  src: url("./fonts/11469c307f0de6481e3a04cc5d54ae93.svg#Uber Move Text") format("svg");
}
* {
  font-size: 100%;
  font-family: MyFont;
} */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gruppo&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'inter';
}
.App {
  //text-align: center;
  header {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    .Main__Logo {
      display: block;
      max-width:230px;
      max-height:95px;
      width: auto;
      height: auto;
    }
  }
  .navbar {
    padding-top: 0;
    max-width: 100vw;
    min-height: 10vh;
    .container {
      margin-left: 30px;
      margin-right: 30px;
    }
    a {
      padding-top: 0;
    }
    img {
      display: block;
      max-width:230px;
      max-height:95px;
      width: auto;
      height: auto;
    }
    .Language {
      margin: 'auto';
      display: 'flex';
      margin-right: 0px !important;
      svg {
        width: 24px;
        margin-right: 4px;
      }
    }
  }
  .Title {
    width: 100%;
    margin: 4px auto 32px auto;
    //color: #000;
    //border: 1px solid black;
    width: 60%;
    padding: 16px 32px;
    letter-spacing: .1rem;
    color: #4F4F4F;
    //background-image: linear-gradient(to right, black 0%, black 50%, #fff 50%, #fff 100%);
    h1 {
      font-weight: bold;
      width: 750px;
      font-size: 76px;
      margin: auto;
      margin-bottom: 8px;
      text-align: center;
      text-justify: inter-word;
    }
    h6 {
      text-align: center;
      text-justify: inter-word;
      width: 500px;
      font-size: 28px;
      //background-color: rgb(250, 255, 240);
      margin: auto;
      //letter-spacing: 0.2rem
    }
  }
  .Search__Input {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: auto;
    margin-bottom: 16px;
    position: relative;
    #Chat__Input {
      text-align: center;
    }
    .ant-input-suffix {
      margin: 0 !important;
      button {
        background-color: #F2C94C;
        margin-bottom: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        &:hover {
          cursor: pointer;
          background-color: #F2C94C;
          filter: brightness(0.9);
        }
      }
    }   
    .ant-input-affix-wrapper {
      height: 50px;
      border-radius: 6px;
      &:where(.css-dev-only-do-not-override-mxhywb).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        //border-color: #46aa79;
      }
    }
    .ant-input-affix-wrapper {
      border-color: #F2C94C;
      //border-color: #F2C94C;
      //outline-color: rgb(250, 255, 240);
    }

    .ant-input-affix-wrapper-focused {
      border-color: rgb(250, 255, 240);
      box-shadow: 0 0 2px #F2C94C
    }
    input {
      margin-left: 8px;
    }
    p {
      margin-top: 4px;
      font-size: 0.7rem;
      color: #cdcdcd;
    }
    button {
      margin-left: auto;
      margin-bottom: 4px;
      top: 5px;
      //width: 40px;
      //height: 40px;
      border-radius: 6px;
      color: white;
      background-color: transparent;
      outline: none;
      border: none;
      //margin-left: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 6px;
      opacity: 0.8;
      svg{
        font-size: 16px;
        margin-right: 3px;
      }
      &:hover {
        background-color: rgb(152, 162, 179);
        color: #fff !important;
        cursor: pointer;
        //background-color: #464f5f
      }
    }

  }
  #container {
    position:relative;
    #gradient {
      position:absolute;
      z-index:2;
      right:0; top:0; left:0;
      height:50px;
      background: url(data:image/svg+xml;base64,alotofcodehere);
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(90%,rgba(255,255,255,1)));
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
      background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
      background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
      background: linear-gradient(to top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%);
    }
  }
  .Conversation__Box {
    min-height: 50vh;
    max-height: 50vh;
    display: flex;
    justify-content: center;
    width: 50%;
    margin: auto;
    margin-bottom: 16px;
    #chat-panel {
      border-radius: 5px;
      box-shadow: rgb(0, 0, 0) 2px 2px 4px 0px;
      opacity: 1;
    }
  }
  .Promps__Suggestions {
    width: 50%;
    min-width: 900px;
    margin: auto;
    .First__Row, .Second__Row {
      //display: inherit;
      display: flex;
      justify-content: center;
      //flex-wrap: wrap;
      .First__Prompt {
        //flex: 1 25%;
        margin: 25px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        min-width: 180px;
        width: 180px;
        height: 80px;
        //margin-left: 25px;
        //margin-right: 25px;
        background-color: #f9f9f9;
        border: 1px solid #F2C94C;
        color: #575151;
        &:hover {
          background-color: #F2C94C;
          color: #fff
        }
        p {
          margin: 0;
        }
      }
    }
    .First__Row {
      .First__Prompt {
        //max-width: 33% !important;
      }
    } 
  }
  footer {
    min-height: 15%;
    margin-top: 32px;
    //margin-bottom: 32px;
    //border-top: 1px solid lightgray;
    //padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    //background-color: #f2f2f2;
    .Footer__Content {
      display: flex;  
      justify-content: center;
      div {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    } 
    img {
      display: block;
      max-width:125px;
      max-height:95px;
      width: auto;
      height: auto;
      margin-bottom: 8px;
    }
    p {
      padding: 0;
    }
    .Social__Row {
      & div {
        padding: 12px !important;
      }
      a {
        margin: 0px !important;
      }
    }
    .Footer__Links {
      text-align: start;
    }
    .Powered__By {
      border-top: 1px solid lightgray;
      padding-top: 16px;
      margin-top: 16px;
    }
    .row { // Mo's updates
      padding-top: 0;
    }
    .First__Column { // Mo's updates
      display: flex;
      justify-content: end;
      flex-direction: column;
      img {
        margin: auto;
      }
      p {
        margin: 0;
      }
    }
    .Social__Row { // Mo's updates
      display: flex;
      justify-content: space-evenly;
      align-items: end;
    }
    .Redirect__Links {
      display: flex;
      justify-content: space-evenly;
      align-items: end;
    }

    a {
      color: #4F4F4F;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .row > div {
      display: flex;
      justify-content: center;
      align-items: end;
      p {
        margin: 0;
        white-space: nowrap;
        a {
          font-size: 1.3rem;
          font-weight: bold;
        }
      }
    }
  }
}

.ant-modal-content {
  .ant-modal-body {
    margin-top: 32px;
    margin-bottom: 16px;
    .ant-input-prefix {
      font-size: 20px;
    }
    input {
      height: 40px;
      font-size: 14px;
      padding-left: 8px;
    }
    button {
      background-color: #00A67E;
      color:  #fff;
      margin: 16px auto;
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 8px 16px;
    }
  }
}

.ant-modal-footer {
  button {
    padding: 8px 16px;
    border-radius: 6px;
    background-color: transparent;  
    border: 1px solid rgb(146, 146, 147);
    color: rgb(146, 146, 147);
    &:hover {
      color: white;
      background-color: #F2C94C;
      border-color: #F2C94C;
    }
  }
}

@media only screen and (max-width: 600px) {
  .Search__Input {
    width: 70% !important;
    margin-bottom: 0px !important;
  }
  .Conversation__Box {
    width: 80vw !important;
  }
  .First__Prompt {
    min-width: 150px !important;
    width: 150px !important;
  }
  .Promps__Suggestions {
    .First__Prompt {
      height: 30px !important;
      margin: 0px auto 8px auto !important;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .Title {
    margin: 8px auto !important;
    width: 90% !important;
    h1, h6 {
      width: 100% !important;
    }
  }
  .First__Prompt {
    width: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .Promps__Suggestions {
    min-width: 100% !important;
  }
  .Title > h1 {
    font-size: 50px !important;
  }
}

@media only screen and (max-width: 575px) {
  .First__Prompt {
    max-width: 100% !important;
    max-width: 90% !important;
    width: 300px !important;
  }
  .Search__Input {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 95% !important;
    button {
      font-size: 12px !important;
      svg {
        font-size: 12px !important;
      }
    }
  }
  .Title {
    padding: 16px 8px !important;
    h1 {
      font-size: 35px !important;
      margin-bottom: 24px !important;
    }
    h6 {
      font-size: 16px !important;
    }
  }
  header {
    margin: 8px auto !important;
    .Main__Logo {
      max-width: 130px !important;
    }
  }

}

.ant-checkbox-wrapper {
  margin-right: 8px;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    border-color: #F2C94C !important;
    background-color: #F2C94C !important;
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #F2C94C !important;
  }
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  border-color: #F2C94C !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #F2C94C !important;
  background-color: #F2C94C !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #F2C94C !important;
}
