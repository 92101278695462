.Message {
    display: flex;
    flex-direction: column;
    .Bubble {
        width: fit-content;
        padding: 8px 16px;
        border-radius: 4px;
        max-width: 70%;
    }
}
.Person {
    margin: 8px 8px 8px auto;
    align-items: end;
    & span {
        text-align: end;
    }
    .Bubble {
        color: #000;
        background-color: #F2F4F7;
    }
}
.Ai {
    margin: 8px auto 8px 8px;
    align-items: start;
    & span {
        text-align: start;
    }
    .Bubble {
        color: #fff;
        background-color: #57d497;
    }
}